var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$apollo.loading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_c('div',{staticClass:"settings_root"},[_c('div',{staticClass:"text-h2 ma-12"},[_vm._v("User Settings for "+_vm._s(_vm.me.name))]),_c('v-card',{staticClass:"ma-12"},[_c('v-card-title',[_vm._v(" Site Settings")]),_c('v-card-text',[_c('v-checkbox',{staticClass:"ma-4",attrs:{"label":"Allow my profile to be public","input-value":_vm.me.publicDisplay},on:{"change":function a(val) {
              _vm.setPublicDisplay(!!val);
            }}}),_c('span',{attrs:{"color":"lightgrey"}},[_vm._v(" If enabled, your user profile will be shown in the leaderboard and your statistics are publicly available")])],1)],1),_c('v-card',{staticClass:"ma-12"},[_c('v-card-title',[_vm._v(" Email Settings ")]),_c('v-card-text',[_vm._l((_vm.reportScales),function(scale){return _c('v-checkbox',{key:scale,staticClass:"ma-4",attrs:{"label":'Update me every ' + scale.toLowerCase(),"input-value":_vm.isActive(scale)},on:{"change":function a(val) {
              _vm.updateReportInterval(scale, !!val);
            }}})}),_vm._v(" Your emails will be delivered to "),_c('span',{attrs:{"color":"lightgrey"}},[_vm._v(" "+_vm._s(_vm.me.email))])],2)],1),_c('v-card',{staticClass:"ma-12"},[_c('v-card-title',[_vm._v("GDPR Stuff")]),_c('v-card-text',[_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.exportMe()}}},[_vm._v("Export my data")]),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.exportDialog),callback:function ($$v) {_vm.exportDialog=$$v},expression:"exportDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Your Export is in progress ")]),_c('v-card-text',[(_vm.exportLoading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.exportState)+" ")])]),_c('v-card-actions',[_c('v-spacer'),(!_vm.exportLoading)?_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.exportDialog = false}}},[_vm._v(" Close ")]):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-4",attrs:{"color":"red"}},'v-btn',attrs,false),on),[_vm._v("Delete my account")])]}}]),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Do you really want to delete your account? ")]),_c('v-card-text',[_c('v-btn',{staticClass:"ma-4",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteMe()}}},[_vm._v("Yes, I want to!")]),(_vm.deleteLoading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):(_vm.deleteState)?_c('div',[_vm._v(" "+_vm._s(_vm.deleteState)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.deleteLoading)?_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Close ")]):_vm._e()],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }